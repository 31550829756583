// export async function getServerSideProps(context) {
//     console.log("masuk home");
//     var { query } = context;
//     return {
//     redirect: {
//         destination: '/',
//         permanent: false,
//     },
//     }
// }

// export default function Redirecting(){
//     return 'redirecting....';
// }

import React from 'react';
import NavBar from "@/components/MainWebsite/Header";
import MainBanner from '@/components/Common/new/MainBanner';
import BusineesPartner from '@/components/MainWebsite/BusineesPartner';
import IndustriesWeServe from '@/components/MainWebsite/IndustriesWeServe';
import Gallery from '@/components/Common/new/Gallery';
import EmailSubscription from '@/components/MainWebsite/EmailSubscription';
import Footer from "@/components/MainWebsite/Footer";
//api
import Companies from "../api/new/company";
import Industry from "../api/new/industry";
import Scroll from 'react-scroll';
// import { FloatingButton, Item } from "react-floating-button";
import { FaWhatsapp } from "react-icons/fa";
import BackendConfig from '@/config/backend-config';
import Careers from '@/components/Common/new/Careers';

const Home = ({scrollTo,companies,industries,main_banner,news,gallery,dealer,sosmed}) => {
    const scroll = Scroll.scroller;
    React.useEffect(() => {
        if(scrollTo){
            setTimeout(function(){ document.getElementById(scrollTo).scrollIntoView({behavior: "smooth"}); }, 500);
        }
    },[])

    //console.log('env', process.env.NEXT_PUBLIC_API_GRAPHQL_URI)
    //console.log('url', BackendConfig.uri)

    var galleries = (gallery)?gallery.map((data)=>data.attributes.url):null;
    return (
        <>
            <NavBar />
            <MainBanner company='Thamrin' link_to="business_partner" main_banner={main_banner} news={news}/>
            <BusineesPartner companies={companies?companies.filter((i)=>i.attributes.Name != 'Thamrin'):[]}/>
            <IndustriesWeServe industries={industries}/><br/><br/>
            {/* <Careers /> */}
            <Gallery galleries={galleries} title="Thamrin Group Gallery"/>
            <EmailSubscription />
            <Footer info={dealer} social={sosmed} desc={companies?companies.filter((i)=>i.attributes.Name == 'Thamrin')[0].attributes.Description:[]}/>
            {/* <a href="#" className="float" onClick={()=>{
                if(confirm("Send whatsapp to this number ?")){
                    window.location = `https://wa.me/62${data.WA}`;
                }
            }}>
                <FaWhatsapp fill={'white'} size={'60px'} className="my-float"/>
            </a> */}
        </>
    )
}

export async function getServerSideProps(context) {
    const scrollTo = context.query.s||null;
    var props = {};
    try{var res = await Companies.companies();
    // console.log('res["DATA"]',res);
    if (res["STATUS"] === 1) {
        props.companies = res["DATA"]["companies"].data;
        props.main_banner = res["DATA"]["main_banner"].data;
        props.gallery = res["DATA"]["gallery"].data[0].attributes.Gallery.data;
        props.sosmed = res["DATA"]["social"].data[0].attributes.Social_Medias.data;
        props.news = res["DATA"]["news"].data;
        props.dealer = res["DATA"]["dealers"].data[0].attributes.Dealers.data[0].attributes;

    }
    res = await Industry.industries();
    if (res["STATUS"] === 1) {
        props.industries = res["DATA"]["industries"].data;
    }}catch(e){console.log(e);}
    props["scrollTo"] = scrollTo;
    return {
        props, // will be passed to the page component as props
    };
}


export default Home;