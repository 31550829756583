import React from 'react';
import Link from 'next/link';
import * as Icon from 'react-feather';
import Image from 'next/image'
import BackendConfig from "@/config/backend-config";

const OurServices = ({companies}) => {
    const partners = companies.map((data, index) => {
		return (
            <div className="col-xl-4 col-md-6" key={index}>
                <div className='single-repair-services center-content'
                style={{
                    'objectFit':'fill',
                    width:'100%',
                    height:'340px',
                    'backgroundImage' : (data.attributes.Background.data)?`url('${BackendConfig["uri"]}${data.attributes.Background.data.attributes.url}')`:'',
                    position : 'relative',
                    overflow : 'hidden'
                }}
                >
                    <div className="wrap-img img-business center-content" >
                        <Image
                            alt='image'
                            src={`${BackendConfig["uri"]}${data.attributes.Icon.data.attributes.url}`}
                            layout='fill'
                            objectFit='contain'
                        />
                    </div>

                    {/* <Image
                        src={`/api/util?img=${encodeURIComponent(data.attributes.Icon.data.attributes.url)}`}
                        alt="about"
                        width={290}
                        height={100}
                    /> */}

                    <div className='business-bottom'>
                        <h3 className='notranslate'>{data.attributes.Business_name}<br /> {data.attributes.Name}</h3>
                        <Link href={
                            (data.attributes.Website)
                                ?data.attributes.Website
                                :((data.attributes.Ready == 1))
                                    ?`/${data.attributes.Name}`
                                    :"/coming-soon"
                            } target="_blank">
                            <a target="_blank"><Icon.ArrowRight /></a>
                        </Link>
                    </div>
                    
                </div>
            </div>
		);
	}); 
    return (
        <div id="business_partner" className="iot-services-area pt-80 pb-50">
            <div className="container justify-content-center">
                <div className="section-title">
                    <h2>Our Business</h2>
                    <div className="bar"></div>
                    <p> <label className="txt-thamrin notranslate">Thamrin Group</label>&nbsp;has several Business Partners as its Business Units, among others</p>
                </div>
                
                <div className="row">
                    {partners}
                </div>
            </div>
        </div>
    );
}

export default OurServices;

