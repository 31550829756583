import React, { useEffect } from 'react';
import { Link as LinkScroll } from 'react-scroll'
import Link from 'next/link';
import dynamic from 'next/dynamic';
import BackendConfig from "@/config/backend-config";
import parse from 'html-react-parser';


const MainBanner = ({company, main_banner, link_to, banner, className}) => {
    var defaultValue;
    if(company == 'thamrin'){
        defaultValue = {
            Title:"Welcome To Thamrin Group",
            Description:"Thamrin Group adalah perusahaan terkemuka di Sumatera Selatan & Bengkulu dengan fokus bisnis pada sektor otomotif dan properti.",
            Image:'/images/bigdata-analytics/main-banner.jpg'
        }
    }
    else if(company == 'yamaha'){
        defaultValue = {
            Title:"Exclusif Motor Untuk Anda",
            Description:"PT.Thamrin Brother's Yamaha merupakan sebuah bisnis company dari Thamrin Group yang bergerak dalam bidang penjualan produk Motor dan Sparepart Yamaha.",
            Image:'/images/Yamaha/banner.jpg'
        }
    }
    else{
        defaultValue = {
            Title:"Exclusif Motor Untuk Anda",
            Description:"",
            Image:'/images/Yamaha/banner.jpg'
        }
    }

    // useEffect(() => {
    //     var el = document.getElementById('title-banner');
    //     console.log(el.innerHTML);
    // }, [])
    
    const Main_Banner = (main_banner && main_banner.length > 0 && main_banner[0].attributes.Contents.data && main_banner[0].attributes.Contents.data.length > 0) ? main_banner[0].attributes.Contents.data.map((data, idx) => {
        
        return(
            <div key={idx} className={`bigdata-analytics-banner ${company != 'Thamrin' ? 'home-company' : ''}`} style={{
                'backgroundImage' : `url('${BackendConfig["uri"]}${data.attributes.Image.data.attributes.url}')`,
                }}>
                <div className='container'>
                    <div className={`bigdata-analytics-content ${company != 'Thamrin' ? 'unset-mt' : ''}`} >
                        {/* <h1 id="title-banner">Hello</h1> */}
                        <h1 >{parse(data.attributes.Title)}</h1>
                        {parse(data.attributes.Description)}
                        {(data.attributes.Link)
                        ?(<a href={`./${data.attributes.Link}`}  className="btn btn-primary">
                            Read Now
                        </a>)
                        :(<LinkScroll to={link_to} spy={true} offset={80} smooth={true} className="btn btn-primary">
                        Get Started
                        </LinkScroll>)
                        }
                    </div>
                </div>
                
            </div>
        )
    }):(
        <div className="bigdata-analytics-banner" style={{
            'backgroundImage' : `url('${defaultValue.Image}')`,
            
            }}>
            <div className="bigdata-analytics-content" >
                <h1>{defaultValue.Title}</h1>
                <p>{defaultValue.Description}</p>
                <LinkScroll to="business_partner" className="btn btn-primary" spy={true} smooth={true}>
                    Get Started
                </LinkScroll>
            </div>
        </div>
    );
    
    const Banners = (banner && banner.length >0)?banner.map((data,idx)=>{
        return (
            // <Link href={`/news/${data.id}`}>
                <div key={idx} className={`bigdata-analytics-banner ${company != 'Thamrin' ? 'home-company' : ''}`} style={{
                'backgroundImage' : `url('/api/util?img=${encodeURIComponent(data.attributes.url)}')`
                }}>
                    {/* <div className="bigdata-analytics-content newsTitle" >
                        <h1>{data.attributes.Title}</h1>
                    </div> */}
                </div>
            // </Link>
        );
    }):"";
    const OwlCarousel = dynamic(import('react-owl-carousel3'));
    const options = {
        center:false,
        loop: false,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        margin: 0,
        autoplayTimeout: 5000,
        autoWidth:false,
        responsive: {
            0:{
                items: 1,
            },
        },
    }
    return (
        <div id="home" className={className}>
            <OwlCarousel className="ml-projects-slides owl-carousel fullw owl-theme"
                {...options}>
                {Main_Banner}
                {Banners}
            </OwlCarousel>
        </div>
    )
}

export default MainBanner;  