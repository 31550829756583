import React from 'react';
import subscription from '../../api/subscription/subscription';

const Newsletter = () => {
    const [email, setEmail] = React.useState("");
    return (
        <div id="subscribe" className="free-trial-area">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="free-trial-image">
                            <img src="/images/email-subscribe-bg.png" alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="free-trial-content">
                            <h2>Enter Your Email For Get Info & Promo</h2>

                            <form className="newsletter-form" onSubmit={async (e)=>{
                                e.preventDefault();
                                if(email&&email!=""){
                                    var res = await subscription.subscribe(email);
                                    setEmail("");
                                    if(res["STATUS"]==1){
                                        alert("Email Subscribed!!");
                                    }else{
                                        if(res["DATA"].toUpperCase().includes("UNIQUE")){
                                            alert("Email Already Subscribed!");
                                        }
                                    }
                                }
                            }}>
                                <input onChange={(e)=>setEmail(e.target.value)}name="email" type="email" className="input-newsletter" value={email} placeholder="Enter your business email here" />
                                <button type="submit">Sign Up Free</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape2 rotateme">
                <img src="/images/shape2.svg" alt="shape" />
            </div>
            <div className="shape4">
                <img src="/images/shape4.svg" alt="shape" />
            </div>
            <div className="shape7">
                <img src="/images/shape4.svg" alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src="/images/shape2.svg" alt="shape" />
            </div>
        </div>
    );
}

export default Newsletter;