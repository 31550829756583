import apollo from "@/lib/apollo.js";

async function subscribe(email,token="") {
	var res = await apollo.mutation(
        `
            mutation($input: SubscriptionInput!){
                createSubscription(data:$input){
                    data{
                        id
                    }
                }
            }
        `,
        token,
        {
          input: {
            Email:email,
            publishedAt:new Date()
          },
        }
    );
	return res;
}


module.exports = {subscribe};