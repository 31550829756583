import React from 'react';
import Link from 'next/link';

const IndustriesWeServe = ({industries}) => {
    const Industry = industries?industries.map((data,index)=>{
        return (
            <div className="col-lg-3 col-6 col-sm-6 col-md-4" key={index}>
                <div className="single-industries-serve-box">
                    <div className="icon" dangerouslySetInnerHTML={{ __html: data.attributes.Icon }}/>
                    {data.attributes.Name}
                </div>
            </div>
        );
    }):"";
    return (
        <div id="industri" className="industries-serve-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>Industrial Sector</h2>
					<div className="bar"></div>
                    <p>We serve various industrial sectors, among others</p>
                </div>

                <div className="row justify-content-center">
                    {Industry}
                </div>
            </div>

            <div className="analytics-shape2">
                <img src="/images/bigdata-analytics/vector.png" alt="image" />
            </div>
        </div>
    )
}

export default IndustriesWeServe;  